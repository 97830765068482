<template>
  <div v-if="customFieldsExist" :class="containerClasses">
    <div
      :class="[{ 'v-row': isInline }, ...fieldClasses]"
      v-for="field in customFields"
      :key="field.name">
      <h3 v-if="field.label">
        {{ field.label }}:
        <help-icon-tooltip v-if="field.description">{{ field.description }}</help-icon-tooltip>
      </h3>
      <component
        :data-testid="toKebabCase(field.label)"
        single-line
        :is="`${customFieldComponents[field.type]}-old`"
        :field="field"
        :warehouse="warehouse"
        v-model="field.value"></component>
    </div>
  </div>
</template>

<script>
/**
 * Custom Fields shown in create appointment form
 * @displayName Custom Fields
 */
import CustomFieldActionOld from '@/modules/custom_fields/components/field_types/old/CustomFieldActionOld.vue';
import CustomFieldBigstringOld from '@/modules/custom_fields/components/field_types/old/CustomFieldBigstringOld.vue';
import CustomFieldBoolOld from '@/modules/custom_fields/components/field_types/old/CustomFieldBoolOld.vue';
import CustomFieldComboboxOld from '@/modules/custom_fields/components/field_types/old/CustomFieldComboboxOld.vue';
import CustomFieldDateOld from '@/modules/custom_fields/components/field_types/old/CustomFieldDateOld.vue';
import CustomFieldDocumentOld from '@/modules/custom_fields/components/field_types/old/CustomFieldDocumentOld.vue';
import CustomFieldDropdownmultiselectOld from '@/modules/custom_fields/components/field_types/old/CustomFieldDropdownmultiselectOld.vue';
import CustomFieldDropdownOld from '@/modules/custom_fields/components/field_types/old/CustomFieldDropdownOld.vue';
import CustomFieldEmailOld from '@/modules/custom_fields/components/field_types/old/CustomFieldEmailOld.vue';
import CustomFieldInputBaseOld from '@/modules/custom_fields/components/field_types/old/CustomFieldInputBaseOld.vue';
import CustomFieldMultidocumentOld from '@/modules/custom_fields/components/field_types/old/CustomFieldMultidocumentOld.vue';
import CustomFieldNumberOld from '@/modules/custom_fields/components/field_types/old/CustomFieldNumberOld.vue';
import CustomFieldPhoneOld from '@/modules/custom_fields/components/field_types/old/CustomFieldPhoneOld.vue';
import CustomFieldStringOld from '@/modules/custom_fields/components/field_types/old/CustomFieldStringOld.vue';
import CustomFieldTimestampOld from '@/modules/custom_fields/components/field_types/old/CustomFieldTimestampOld.vue';
import { toKebabCase } from '@nova/core';

export default {
  name: 'CustomFields',
  components: {
    CustomFieldActionOld,
    CustomFieldBigstringOld,
    CustomFieldBoolOld,
    CustomFieldComboboxOld,
    CustomFieldDateOld,
    CustomFieldDocumentOld,
    CustomFieldDropdownmultiselectOld,
    CustomFieldDropdownOld,
    CustomFieldEmailOld,
    CustomFieldInputBaseOld,
    CustomFieldMultidocumentOld,
    CustomFieldNumberOld,
    CustomFieldPhoneOld,
    CustomFieldStringOld,
    CustomFieldTimestampOld
  },
  props: {
    /**
     * Warehouse entity providing the custom fields template
     */
    warehouse: Object,
    appointment: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    initialCustomFields: {
      type: Array,
      required: false,
      default: null
    },
    isInline: {
      type: Boolean,
      default: true
    },
    containerClasses: {
      type: Array,
      default() {
        return ['my-4'];
      }
    },
    fieldClasses: {
      type: Array,
      default() {
        return ['mb-4'];
      }
    },
    singleLine: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    customFieldsExist() {
      return this.customFields?.length;
    },
    customFieldComponents() {
      // Auto generate component names for dynamic importing the Custom Fields components
      const componentsMap = {};
      Object.entries(this.novaCore.CustomFieldType).forEach(
        type => (componentsMap[type[1]] = `custom-field-${type[0].toLowerCase()}`)
      );
      return componentsMap;
    },
    existingCustomFields() {
      return this.initialCustomFields?.length
        ? this.initialCustomFields
        : this.appointment?.customFields;
    }
  },
  data() {
    return {
      customFields: [],
      areCustomFieldsSet: false
    };
  },
  methods: {
    toKebabCase,
    getRequiredValue(field) {
      this.novaCore.getRequiredValue(field, false);
    },
    setCustomFields() {
      this.customFields = this.novaCore.updateCustomFieldsFromWarehouse(
        this.existingCustomFields,
        this.warehouse,
        false
      );
    }
  },
  mounted() {
    this.setCustomFields();
  },
  watch: {
    warehouse() {
      this.setCustomFields();
    },
    customFields() {
      /**
       * Emits input event with new customFields data
       * @event input
       * @property {object} input - customFields data
       */
      this.$emit('update:model-value', this.customFields);
    }
  }
};
</script>
