<template>
  <div>
    <v-dialog width="auto" max-width="auto" v-model="isActive">
      <template v-slot:activator="{ props: activatorProps }">
        <template v-if="iconMode">
          <slot name="icon-trigger" :open-date-picker="openDatePicker">
            <div class="text-center">
              <v-btn
                icon
                size="large"
                :data-testid="`${testId}-icon-activator`"
                @click="isActive = true"
                color="secondary"
                :ripple="hasRipple">
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
            </div>
          </slot>
        </template>
        <div class="d-flex justify-center align-content-center" v-else-if="noInputMode">
          <v-btn
            :ripple="hasRipple"
            @click="isActive = true"
            secondary
            variant="plain"
            :data-testid="`${testId}-icon-activator`"
            :disabled="disabled">
            <v-icon>mdi-calendar</v-icon>
            <span class="ml-2" v-if="inputValueDisplay">
              {{ inputValueDisplay }}
            </span>
          </v-btn>
        </div>
        <template v-else-if="externalTrigger">
          <slot name="external-trigger" :open-date-picker="openDatePicker"></slot>
        </template>
        <v-text-field
          v-else
          ref="datePickerInputField"
          :min-width="fieldMinWidth || 'auto'"
          @click="isActive = true"
          :density="fieldDensity"
          :data-testid="testId"
          :variant="outlined ? 'outlined' : 'underlined'"
          readonly
          :class="{
            required: required,
            'datepicker-with-label': Boolean(label),
            'ml-4': !displayFieldIcon && !noMargins,
            'mt-4': !noMargins,
            'mt-0': noMargins
          }"
          :disabled="disabled"
          :error="error"
          :prepend-icon="displayFieldIcon && !innerIcon ? 'mdi-calendar' : ''"
          :prepend-inner-icon="displayFieldIcon && innerIcon ? 'mdi-calendar' : ''"
          :rules="validationRules"
          :required="required"
          :placeholder="computedPlaceHolder"
          hide-details="auto"
          v-model="inputValueDisplay"
          :label="displayFieldLabel ? placeholder : ''"
          @click:clear="isRange ? (selectedDates = []) : (selectedDates = '')"
          @blur="selectedDates = parseDates(formattedDates)"
          :clearable="clearable"></v-text-field>
      </template>

      <template v-slot:default="{ isActive }">
        <v-date-picker
          class="date-picker"
          :density="compactDisplay ? 'compact' : 'default'"
          :prepend-inner-icon="innerIcon && displayFieldIcon ? 'mdi-calendar' : null"
          :prepend-icon="displayFieldIcon && !innerIcon ? 'mdi-calendar' : ''"
          v-model="localValue"
          hide-details="auto"
          :multiple="isRange"
          :hide-header="hideHeader"
          :clearable="clearable"
          :label="displayFieldLabel ? label : null"
          :error="error"
          :rules="customValidationRules"
          min-width="13rem"
          :min="minDate"
          :max="maxDate"></v-date-picker>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import moment from 'moment-timezone';

/**
 * Datepicker
 * @displayName Date Picker
 */
export default {
  props: {
    /**
     * @model
     */
    modelValue: [String, Array],
    /**
     * Disable datepicker
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Datepicker label
     */
    label: {
      type: String,
      required: false
    },
    displayFieldLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    displayFieldIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    compactDisplay: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    iconMode: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: String,
      required: false,
      default: '20px'
    },
    innerIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    format: {
      type: String,
      required: false,
      default: 'MM/DD/YYYY'
    },
    /*
     * It shows the selected date on a label instead of a input
     */
    noInputMode: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    minDate: {
      type: String,
      required: false
    },
    maxDate: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    customValidationRules: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    openDates: {
      type: Array,
      required: false
    },
    hasRipple: {
      type: Boolean,
      required: false,
      default: true
    },
    datePickerWrapperClass: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      required: false
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    fieldDensity: {
      type: String,
      required: false,
      default: 'compact'
    },
    isRange: {
      type: Boolean,
      required: false,
      default: false
    },
    externalTrigger: {
      type: Boolean,
      required: false,
      default: false
    },
    fieldMinWidth: {
      type: String,
      required: false,
      default: '150px'
    },
    noMargins: {
      type: Boolean,
      required: false,
      default: false
    },
    testId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      selectedDates: this.isRange ? [] : '',
      formattedDates: this.isRange ? [] : '',
      isActive: false
    };
  },
  methods: {
    formatDates(dates) {
      let formattedDates = this.isRange ? [] : '';
      if (dates) {
        if (!Array.isArray(dates)) {
          formattedDates = this.formatDate(dates);
        } else {
          formattedDates = dates.map(date => {
            return this.formatDate(date);
          });
        }
      }

      return formattedDates;
    },
    /**
     * Format the date from YYYY-MM-DD to MM/DD/YYYY format
     * @public
     * @param date
     * @returns {string|null}
     */
    formatDate(date) {
      if (!date) return null;

      return moment.utc(date, 'YYYY/MM/DD').format(this.format);
    },
    parseDates(dates) {
      let parsedDates = this.isRange ? [] : '';
      if (dates) {
        if (!Array.isArray(dates)) {
          parsedDates = this.parseDate(dates);
        } else {
          parsedDates = dates.map(date => {
            return this.parseDate(date);
          });
        }
      }

      return parsedDates;
    },
    /**
     * Parse date from MM/DD/YYYY to YYYY-MM-DD
     * @public
     * @param date
     * @returns {string|null}
     */
    parseDate(date) {
      date = String(date);
      if (!date) return null;

      return moment.utc(date, this.format).format('YYYY-MM-DD');
    },
    /**
     * Disable dates that are not available
     * TODO: This needs real dates
     * @param val
     * @returns {*}
     */
    allowedDates(val) {
      if (this.isRange) {
        return this.selectedDates?.length === 1 ? val >= this.selectedDates[0] : true;
      } else {
        return this.openDates?.length > 0 ? this.openDates.includes(val) : true;
      }
    },
    openDatePicker() {
      this.isActive = true;
    }
  },
  beforeMount() {
    if (this.modelValue) {
      this.formattedDates = this.formatDates(this.modelValue);
      this.selectedDates = this.modelValue;
    }
  },
  computed: {
    computedPlaceHolder() {
      if (this.placeholder) {
        return this.placeholder;
      }
      let now = moment.utc();
      let placeholder = 'e.g. ' + now.clone().format(this.format);
      if (this.isRange) {
        placeholder += ` - ${now.clone().add('10', 'days').format(this.format)}`;
      }
      return placeholder;
    },
    localValue: {
      get() {
        const transformed = DateTime.fromISO(
          DateTime.fromISO(this.modelValue, { setZone: true }).toISODate()
        ).toJSDate();
        return transformed;
      },
      set(newVal) {
        this.$emit(
          'update:modelValue',
          DateTime.fromJSDate(newVal).toISO({ includeOffset: false })
        );
      }
    },
    inputValueDisplay: {
      get() {
        return Array.isArray(this.formattedDates)
          ? this.formattedDates.join(' - ')
          : this.formattedDates;
      },
      set(value) {
        this.formattedDates = value;
      }
    },
    validationRules() {
      if (this.customValidationRules.length > 0) {
        return this.customValidationRules;
      } else {
        return this.required ? this.$validator.rules.required(this.label || 'Date') : [];
      }
    }
  },
  watch: {
    selectedDates(val) {
      if (val) {
        let valClone = this.novaCore.deepClone(val);
        if (!this.isRange && Array.isArray(val)) {
          valClone = valClone.join('');
        }
        this.formattedDates = this.formatDates(valClone);

        /**
         * Emits input event with new data
         * @event input
         * @property {string} input - new selected date
         */
        this.$emit('update:model-value', valClone);
      } else if (val === '') {
        this.$emit('update:model-value', '');
      }
    },
    formattedDates(val) {
      if (val) {
        /**
         * Emits input event with new data
         * @event input
         * @property {string} input - new selected date
         */
        this.$emit('update:model-value', this.selectedDates);
      }
    },
    modelValue(val) {
      this.formattedDates = this.formatDates(val);
      this.selectedDates = val;
      this.isActive = false;
    },
    datePicker() {
      if (!this.datePicker && this.isRange && this.selectedDates.length < 2) {
        this.selectedDates = [];
        this.formattedDates = [];
      }
    }
  }
};
</script>
