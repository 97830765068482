import { getResponse } from '@satellite/services/serviceUtilities';
import { DateTime } from 'luxon';
import { get } from 'lodash';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async fakeCheckIn(warehouseId, data, options = {}, requestConfig = {}) {
    const response = await axios.put(
      `checkin/public/${warehouseId}/unplanned`,
      data,
      requestConfig
    );

    return getResponse(response, options);
  },
  async detachContainer(assetVisitId, assetContainerId, options = {}, requestConfig = {}) {
    const response = await axios.post(
      `asset-visit/${assetVisitId}/detach/${assetContainerId}`,
      requestConfig
    );

    return getResponse(response, options);
  },
  async attachContainer(assetVisitId, assetContainerId, options = {}, requestConfig = {}) {
    const response = await axios.post(
      `asset-visit/${assetVisitId}/attach/${assetContainerId}`,
      null,
      requestConfig
    );

    return getResponse(response, options);
  },
  async updateAssetContainerStatus(assetContainerId, eventType, options = {}, requestConfig = {}) {
    const response = await axios.post(
      `asset-container/${assetContainerId}/event`,
      { eventType, assetContainerId },
      requestConfig
    );

    return getResponse(response, options);
  },
  async updateAssetVisitStatus(assetVisitId, eventType, options = {}, requestConfig = {}) {
    const response = await axios.post(
      `asset-visit/event`,
      { eventType, assetVisitId },
      requestConfig
    );

    return getResponse(response, options);
  },
  async updateAssetVisit(assetVisitId, data, options = {}, requestConfig = {}) {
    const response = await axios.patch(`asset-visit/${assetVisitId}`, data, requestConfig);

    return getResponse(response, options);
  },
  async getAssetContainersByWarehouseId(warehouseId, params = {}, options = {}) {
    // Get only containers that has no asset visit (dettached)
    const baseParams = {
      s: {
        warehouseId: warehouseId,
        assetVisitId: { $is: null }
      },
      join: [
        'spotAssignments||id,spotId',

        'dropAppointment||id,start,end,status,statusTimeline,dockId,userId,refNumber,confirmationNumber',
        'dropAppointment.dock||id,name,doorNumber',
        'dropAppointment.user||id,firstName,lastName,email',
        'dropAppointment.user.company||id,name',

        'pickupAppointment||id,start,end,status,statusTimeline,dockId,userId,refNumber,confirmationNumber',
        'pickupAppointment.dock||id,name,doorNumber',
        'pickupAppointment.user||id,firstName,lastName,email',
        'pickupAppointment.user.company||id,name'
      ]
    };

    const response = await axios.get(`asset-container`, { params: { ...baseParams, ...params } });
    return getResponse(response, options);
  },
  async getLatestAssetVisitsByWarehouseId(warehouseId, params = {}, options = {}) {
    const baseParams = {
      s: {
        $and: [
          { warehouseId: warehouseId },
          {
            createDateTime: {
              $gte: DateTime.now().minus({ days: 7 }).toISO()
            }
          }
        ]
      },
      join: [
        'company||id,name,scac',
        'appointment||id,start,end,status,statusTimeline,dockId,userId,refNumber,confirmationNumber',
        'appointment.loadType||id,name,direction',
        'appointment.dock||id,name,doorNumber',
        'assetVisitEvents||id,createDateTime,eventType',
        'assetVisitEvents.assetContainer||id,type,code,createDateTime,notes,pickupAppointmentId,dropAppointmentId',
        'assetContainers||id,type,code,createDateTime,notes,pickupAppointmentId,dropAppointmentId',
        'assetContainers.assetContainerEvents||id,createDateTime,eventType',
        'spotAssignments||id,spotId,createDateTime'
      ]
    };

    const response = await axios.get(`asset-visit`, { params: { ...baseParams, ...params } });
    return getResponse(response, options);
  },
  /**
   * Get asset visit by appointment id.
   * @param {string} appointmentId - appointment id
   * @param {Object} options
   * @param {Object} options.params - params for the axios call
   * @param {Object} options.getResponseOptions - options for the getResponse function
   * @param {Object} options.requestConfig - request config for the axios call
   * @returns {Promise<Object>}
   */
  async getAssetVisitByAppointmentId(appointmentId, options = {}) {
    const baseParams = {
      s: {
        appointmentId: appointmentId
      }
    };
    const params = { ...baseParams, ...get(options, 'params', {}) };
    const getResponseOptions = { ...get(options, 'getResponseOptions', {}) };
    const requestConfig = { ...get(options, 'requestConfig', {}) };

    const response = await axios.get(`asset-visit`, {
      ...requestConfig,
      params
    });
    const returnArray = getResponse(response, getResponseOptions);

    if (!Array.isArray(returnArray)) {
      return null;
    }

    return get(returnArray, '0') ?? null;
  }
};
