<script>
import moment from 'moment-timezone';

import config from '@/config/config';
import { RenderModeEnum } from '@/enums';

/**
 * @displayName Navigation Drawer Base
 */
export default {
  name: 'navigation-drawer',
  computed: {
    collapseIconTooltip() {
      return `${this.isCollapsed ? 'Expand' : 'Collapse'} Menu`;
    },
    collapseIcon() {
      return `mdi-arrow-collapse-${this.isCollapsed ? 'right' : 'left'}`;
    },
    navItems() {
      return [
        {
          title: 'Appointments',
          link: 'appointments',
          maximumRole: this.novaCore.UserRole.OWNER,
          minimumRole: this.novaCore.UserRole.SPECTATOR,
          icon: 'mdi-calendar',
          testId: 'appointments-nav-link',
          shouldHide: this.orgRequired
        },
        {
          title: 'Search Appointments',
          link: 'appointments.search',
          maximumRole: this.novaCore.UserRole.OWNER,
          icon: 'mdi-magnify',
          testId: 'search-appointments-nav-link',
          shouldHide: this.orgRequired
        },
        {
          title: 'Warehouses',
          link: 'warehouses',
          maximumRole: this.novaCore.UserRole.OWNER,
          minimumRole: this.novaCore.UserRole.SPECTATOR,
          icon: 'mdi-warehouse',
          testId: 'warehouses-nav-link',
          shouldHide: this.orgRequired
        },
        {
          title: 'Live Yard View',
          link: 'yard',
          maximumRole: this.novaCore.UserRole.OWNER,
          minimumRole: this.novaCore.UserRole.SPECTATOR,
          icon: 'mdi-view-column-outline',
          testId: 'yard-nav-link',
          shouldHide: this.isYardMapEntryHidden
        },
        {
          title: 'My Organization',
          link: 'org',
          maximumRole: this.novaCore.UserRole.OWNER,
          minimumRole: this.novaCore.UserRole.ADMIN,
          icon: 'mdi-domain',
          testId: 'my-organization-nav-link',
          shouldHide: this.orgRequired
        },
        {
          title: 'Users',
          link: 'users',
          maximumRole: this.novaCore.UserRole.GOD,
          minimumRole: this.novaCore.UserRole.SPECTATOR,
          icon: 'mdi-account-group',
          testId: 'users-nav-link',
          shouldHide: this.orgRequired
        },
        {
          title: 'My Carrier Contacts',
          link: 'carriers',
          maximumRole: this.novaCore.UserRole.OWNER,
          minimumRole: this.novaCore.UserRole.SPECTATOR,
          icon: 'mdi-truck',
          testId: 'my-carrier-contacts-nav-link',
          shouldHide: this.orgRequired
        },
        {
          title: 'Orgs',
          link: 'orgs',
          maximumRole: this.novaCore.UserRole.GOD,
          minimumRole: this.novaCore.UserRole.INTERNAL,
          icon: 'mdi-lan',
          testId: 'orgs-nav-link'
        },
        {
          title: 'Insights',
          link: 'insights.reporting',
          maximumRole: this.novaCore.UserRole.OWNER,
          minimumRole: this.novaCore.UserRole.SPECTATOR,
          icon: 'mdi-chart-timeline-variant-shimmer',
          testId: 'insights-nav-link',
          shouldHide: this.orgRequired
        },
        {
          title: 'Help',
          link: 'https://community.loadsmart.com/hc/en-us/sections/24987137080851-New-Warehouse-Portal-Opendock-Nova',
          maximumRole: this.novaCore.UserRole.GOD,
          minimumRole: this.novaCore.UserRole.BASE,
          icon: 'mdi-help-box',
          external: true,
          onClick: 'trackViewHelpPageInMixPanel',
          testId: 'help-nav-link'
        }
      ];
    },
    isStaging() {
      return config.server_env === 'staging';
    },
    isRND() {
      return config.server_env === 'rnd';
    },
    isQA() {
      return config.server_env === 'qa';
    },
    role() {
      return this.$me ? this.novaCore.userRoleToText(this.$me.role) : '';
    },
    navItemsToDisplay() {
      return this.$me ? this.filteredNavItems : [];
    },
    isExpired() {
      return this.trialDaysLeft() <= 0;
    },
    isInternalUser() {
      return this.novaCore.isInternalUser(this.$me);
    },
    isTrial() {
      return this.$org.orgType === this.novaCore.OrgType.Trial;
    },
    remainingTrialMessage() {
      if (this.isTrial && this.isExpired) {
        return 'Trial Expired';
      } else {
        const trialDaysLeft = this.trialDaysLeft();
        return `${trialDaysLeft} ${trialDaysLeft === 1 ? 'Day' : 'Days'}`;
      }
    },
    filteredNavItems() {
      return this.navItems.filter(
        ni =>
          (!ni.minimumRole ||
            (ni.minimumRole &&
              this.novaCore.isUserRoleGreaterOrEqual(this.$me.role, ni.minimumRole))) &&
          !ni.shouldHide
      );
    }
  },
  props: {
    /**
     * Determines if certain items are available based on an existing org
     */
    orgRequired: {
      type: Boolean,
      required: true,
      default: true
    },
    unreadMsgCount: {
      type: Number,
      required: false,
      default: 0
    }
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    /**
     * Navigate to specified URL
     * @public
     * @param {string} url
     */
    navigate(url) {
      this.$router.push({ name: url });
    },
    /**
     * Allows for handling of external/internal links dynamically
     * @param {
          title: String,
          link: String,
          minimumRole: String,
          icon: String,
          external: Boolean
        } item
     * @returns {{to: ({name}|null), href: (*|null), target: (string)}}
     */
    getLinkAttrs(item) {
      return {
        to: !item.external ? { name: item.link } : null,
        href: item.external ? item.link : null,
        target: item.external ? '_blank' : ''
      };
    },
    isItemDisabledForUserType(item) {
      if (item.maximumRole && this.novaCore.isUserRoleGreater(this.$me.role, item.maximumRole)) {
        return true;
      }

      return Boolean(
        item.minimumRole && this.novaCore.isUserRoleLess(this.$me.role, item.minimumRole)
      );
    },
    isItemDisabled(item) {
      return this.isItemDisabledForUserType(item);
    },
    makeDisabledTooltipMsg(item) {
      if (this.isItemDisabledForUserType(item)) {
        return 'Not available for your user role.';
      }

      return 'Not available.';
    },
    handleListItemButtonClick(item) {
      if (item.onClick) {
        this[item.onClick]();
      }
      this.$emit('close');
    },
    trackViewHelpPageInMixPanel() {
      this.mixpanel.track(this.mixpanel.events.VIEW.HELP.PAGE, {
        'Org ID': this.$org?.id,
        'Org Name': this.$org?.name
      });
    },
    trialDaysLeft() {
      return Math.ceil(moment(this.$org.expiresAt).diff(moment(), 'days', true));
    },
    setRenderMode(renderMode) {
      this.trackSwitchModeMixpanelEvent(renderMode);
      localStorage.setItem('renderMode', renderMode);
      location.reload();
    },
    trackSwitchModeMixpanelEvent(renderMode) {
      const event =
        renderMode === 'mobile'
          ? this.mixpanel.events.ACTION.VIEW_MOBILE_VERSION
          : this.mixpanel.events.ACTION.VIEW_DESKTOP_VERSION;
      this.mixpanel.track(event, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id
      });
    }
  },
  data() {
    return {
      config: config,
      drawer: true,
      mini: false,
      neutronVersion: {},
      isCollapsed: false,
      renderModeEnum: RenderModeEnum,
      isYardMapEntryHidden: false
    };
  },
  created() {
    if (this.$vuetify.display.smAndDown) {
      this.drawer = false;
    }
    this.$eventHub.$on('navToggle', () => {
      this.drawer = !this.drawer;
    });

    this.$store.dispatch('App/getNeutronVersion');
    this.isCollapsed = JSON.parse(localStorage.getItem('isNavCollapsed')) ?? false;
  },
  watch: {
    isCollapsed(newVal) {
      localStorage.setItem('isNavCollapsed', newVal);
      if (newVal) {
        document.querySelector('body').classList.add('nav-drawer-collapsed');
      } else {
        document.querySelector('body').classList.remove('nav-drawer-collapsed');
      }
    }
  }
};
</script>
