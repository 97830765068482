<template>
  <div class="d-flex justify-center align-start time-entry">
    <div>
      <v-text-field
        :class="{ 'no-timezone': hideTimezone }"
        density="compact"
        v-bind="$props"
        class="flex-grow-1 text-center input mt-0"
        v-maska="inputMaskOptions"
        :data-testid="`${$attrs['test-id']}-input`"
        :placeholder="militaryTimeEnabled ? '13:00' : '12:00'"
        v-model="time"
        variant="outlined"
        hide-details="auto"
        :rules="timeRules"
        :validate-on="true ? 'blur' : undefined"
        @blur="formatTime"></v-text-field>
      <small class="text-right font-size-x-small" v-if="!hideTimezone">
        {{ warehouse.timezone }}
      </small>
    </div>
    <div class="ml-2" v-if="!militaryTimeEnabled">
      <v-btn-toggle
        v-model="amPm"
        mandatory
        color="primary"
        class="align-end"
        divided
        density="comfortable">
        <v-btn
          height="40"
          variant="outlined"
          value="am"
          :data-testid="`${$attrs.testId}-am-button`"
          :class="{ 'px-5': !compact, 'auto-width': compact }">
          AM
        </v-btn>
        <v-btn
          height="40"
          variant="outlined"
          value="pm"
          :data-testid="`${$attrs.testId}-pm-button`"
          :class="{ 'px-5': !compact, 'auto-width': compact }">
          PM
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { vMaska } from 'maska/vue';

export default {
  directives: {
    maska: vMaska
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default() {
        return {
          amPm: '',
          time: ''
        };
      }
    },
    warehouse: {
      type: Object,
      required: true
    },
    fontSizeLarge: {
      type: Boolean,
      default: true
    },
    inline: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    hideTimezone: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      time: '',
      amPm: ''
    };
  },
  computed: {
    inputMaskOptions() {
      return {
        mask: '##:##',
        eager: true,
        token: {
          '#': {
            pattern: /[0-9]/,
            transform: (char, i, value) => {
              if (this.militaryTimeEnabled) {
                if (i === 0 && char > 2) return '';
                if (i === 1 && value[0] === '2' && char > 3) return '';
              } else {
                if (i === 0 && char > 1) return '';
                if (i === 1 && value[0] === '1' && char > 2) return '';
              }
              if (i === 2 && char > 5) return '';
              return char;
            }
          }
        }
      };
    },
    militaryTimeEnabled() {
      return this.$isMilitaryTimeEnabled(this.warehouse);
    },
    timeRules() {
      if (!this.required && this.time.length === 0) {
        return [];
      } else {
        return this.militaryTimeEnabled ? this.$validator.rules.time24 : this.$validator.rules.time;
      }
    }
  },
  methods: {
    formatTime() {
      if (!this.time) {
        return;
      }
      let parts = this.time.split(':');
      let hour = parts[0];
      let minute = parts[1];

      if (parseInt(hour) < 10 && hour.substring(0, 1) !== '0') {
        hour = pad(hour);
      } else if (!this.militaryTimeEnabled && parseInt(hour) > 12) {
        let carryOver = hour.substring(1);
        hour = pad(hour.substring(0, 1));
        minute = carryOver + minute;
      }

      if (parseInt(minute) < 10) {
        minute = pad(minute);
      }

      if (!minute) {
        minute = '00';
      }

      this.time = `${hour.slice(-2)}:${minute.slice(-2)}`;

      function pad(str, direction = 'left', pad = '0') {
        return direction === 'left' ? pad + str : str + pad;
      }
    },
    updateVal() {
      if (this.time.length === 5 && this.militaryTimeEnabled) {
        this.amPm = DateTime.fromFormat(this.time, 'HH:mm').toFormat('a');
      }

      this.$emit('update:model-value', {
        amPm: this.amPm,
        time: this.time
      });
    },
    updateLocalTime() {
      if (this.modelValue.time.length === 5 && this.militaryTimeEnabled) {
        this.time = DateTime.fromFormat(
          `${this.modelValue.time} ${this.modelValue.amPm}`,
          'hh:mm a'
        ).toFormat('HH:mm');
        this.amPm = DateTime.fromFormat(this.time, 'HH:mm').toFormat('a');
      } else {
        this.time = this.modelValue.time;
        this.amPm = this.modelValue.amPm;
      }
    }
  },
  beforeMount() {
    this.updateLocalTime();
  },
  watch: {
    modelValue: {
      handler() {
        this.updateLocalTime();
      },
      deep: true
    },
    amPm() {
      this.updateVal();
    },
    time() {
      this.updateVal();
    }
  }
};
</script>

<style scoped lang="scss">
.no-timezone {
  width: 75px;
}
</style>
