<template>
  <div class="form-data" :class="{ 'is-list': isList, 'is-compact text-dense': isCompact }">
    <template v-if="isGrid">
      <div class="py-4 value-grid">
        <div class="block-value">
          <span>{{ phoneTitle }}:</span>
          <span>{{ assetVisit.phone ?? '----' }}</span>
        </div>
        <div class="block-value" v-if="showCompanyName">
          <span>{{ companyTitle }}:</span>
          <span>{{ companyName }}</span>
        </div>

        <div
          v-for="data of assetVisitFormData"
          :key="data.id"
          class="block-value"
          :class="keyValueClasses">
          <span>{{ data.label }}:</span>
          <span
            v-if="isDocument(data)"
            v-html="
              getCustomFieldFormattedValue(data, {
                [CustomFieldType.Document]: { generateLink: true }
              })
            "></span>
          <span
            v-else-if="isMultiDocument(data)"
            v-html="
              getCustomFieldFormattedValue(data, {
                [CustomFieldType.MultiDocument]: { generateLink: true }
              })
            "></span>
          <span v-else>{{ getCustomFieldFormattedValue(data) }}</span>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="d-flex key-value-pair" :class="keyValueClasses">
        <div class="d-flex flex-row justify-space-between align-center">
          <div class="d-flex flex-column">
            <strong v-if="boldLabels" class="font-size-x-small">Phone Number</strong>
            <span v-else class="font-size-x-small">Phone Number:</span>
            <span data-testid="asset-visit-form-drivers-phone-value" v-if="!isEditingPhone">
              {{ assetVisit.phone ?? '----' }}
            </span>
          </div>
          <div v-if="allowPhoneEdit">
            <v-btn
              icon
              size="x-small"
              @click="isEditingPhone = true"
              data-testid="drivers-chat-edit-phone-btn">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="allowPhoneEdit && isEditingPhone">
          <phone-number-field
            dense
            v-model="newAssetVisitPhone"
            :validator="$validator"
            outlined
            hide-details
            class="mb-1"
            data-testid="asset-visit-form-drivers-phone"
            placeholder="Driver's Phone"
            :label="null"></phone-number-field>
          <div class="justify-end d-flex flex-row">
            <v-btn class="ml-2" size="small" @click="isEditingPhone = false" variant="outlined">
              Nevermind
            </v-btn>
            <v-btn
              class="ml-2"
              size="small"
              color="primary"
              @click="saveNewAssetVisitPhone"
              data-testid="drivers-chat-save-driver-phone-btn">
              Save
            </v-btn>
          </div>
        </div>
      </div>
      <div class="d-flex key-value-pair" :class="keyValueClasses" v-if="showCompanyName">
        <strong v-if="boldLabels" class="font-size-x-small">Carrier Company</strong>
        <span v-else class="font-size-x-small">Carrier Company:</span>
        <span>{{ companyName }}</span>
      </div>
      <div
        v-for="data of assetVisitFormData"
        :key="data.id"
        class="d-flex key-value-pair"
        :class="keyValueClasses">
        <strong v-if="boldLabels" class="font-size-x-small">{{ data.label }}</strong>
        <span v-else class="font-size-x-small">{{ data.label }}:</span>
        <span
          v-if="isDocument(data)"
          v-html="
            getCustomFieldFormattedValue(data, {
              [CustomFieldType.Document]: { generateLink: true }
            })
          "></span>
        <span
          v-else-if="isMultiDocument(data)"
          v-html="
            getCustomFieldFormattedValue(data, {
              [CustomFieldType.MultiDocument]: { generateLink: true }
            })
          "></span>
        <span v-else>{{ getCustomFieldFormattedValue(data) }}</span>
      </div>
    </template>
  </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue';
import { CustomFieldType, getCustomFieldFormattedValue } from '@nova/core';
import { useAssetVisit, useEventHub } from '@/composables';
import customFormsService from '@satellite/services/custom-forms-service';

export default {
  props: {
    assetVisit: {
      type: Object,
      required: true
    },
    company: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    isList: {
      type: Boolean,
      required: false,
      default: false
    },
    isCompact: {
      type: Boolean,
      required: false,
      default: false
    },
    phoneTitle: {
      type: String,
      required: false,
      default: 'Phone Number'
    },
    companyTitle: {
      type: String,
      required: false,
      default: 'Carrier Company'
    },
    boldLabels: {
      type: Boolean,
      required: false,
      default: true
    },
    isInline: {
      type: Boolean,
      required: false,
      default: true
    },
    isDivided: {
      type: Boolean,
      required: false,
      default: false
    },
    allowPhoneEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    isGrid: {
      type: Boolean,
      required: false,
      default: false
    },
    showCompanyName: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props) {
    const assetVisitFormData = ref([]);
    const isEditingPhone = ref(false);
    const newAssetVisitPhone = ref(null);
    const { updateAssetVisit } = useAssetVisit(props.assetVisit);
    const keyValueClasses = ref({
      'flex-column': !props.isList || !props.isInline,
      'pb-6': !props.isList,
      'pb-2': props.isList,
      divided: props.isDivided
    });

    const eventHub = useEventHub();

    const companyName = computed(() => {
      return props.company?.name ?? props.assetVisit.companyHint;
    });

    async function getAssetVisitFormData() {
      try {
        return customFormsService.getCustomFormData({
          s: { objectId: props.assetVisit.id },
          limit: 1000
        });
      } catch (_) {
        return [];
      }
    }

    function isDocument(field) {
      return field.type === CustomFieldType.Document;
    }

    function isMultiDocument(field) {
      return field.type === CustomFieldType.MultiDocument;
    }

    const saveNewAssetVisitPhone = async () => {
      await updateAssetVisit(props.assetVisit.id, {
        phone: newAssetVisitPhone.value
      });
      isEditingPhone.value = false;
      eventHub.$emit('refresh-AssetVisit', props.assetVisit);
    };

    onMounted(async () => {
      assetVisitFormData.value = await getAssetVisitFormData();
    });

    return {
      assetVisitFormData,
      isDocument,
      isMultiDocument,
      CustomFieldType,
      getCustomFieldFormattedValue,
      keyValueClasses,
      isEditingPhone,
      saveNewAssetVisitPhone,
      newAssetVisitPhone,
      companyName
    };
  }
};
</script>

<style lang="scss" scoped>
.form-data {
  &.is-compact * {
    font-size: 12px !important;
  }
}

.form-data:not(.is-list) {
  display: flex;
  flex-wrap: wrap;

  & > span {
    width: 33%;

    &.full-width {
      width: 100%;
    }
  }
}

.divided {
  &.key-value-pair {
    margin-top: 8px;
    padding-top: 8px;
    padding-bottom: 0 !important;
    border-top: 1px solid $color-line-divider;

    &:first-child {
      margin-bottom: 0;
      border-top: none;
    }
  }
}

.block-value {
  display: flex;
  flex-direction: column;

  > span:nth-child(1) {
    font-weight: bold;
  }
}

.value-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
}
</style>
